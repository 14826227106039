import React from 'react'
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import { Link } from 'gatsby'

const TermsOfService = () => {
    return (
        <Layout page="Terms of Service">
            <PageBanner
                pageTitle="Terms of Service" 
                crumbs={[{page: "Home", url: "/"}]} 
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <p><i>This Terms of Service and Website Use was last updated on February 1, 2021.</i></p>
                                <h3>Terms of Service & Terms of Website Use</h3>
                                <blockquote className="blockquote">
                                    <p>This page (and the documents it refers to) tells you the terms of use on which you may use our website www.connectiongoal.com (our site), whether as a guest or a registered user. Please read these terms of use carefully before you start to use the site. By using our site, you accept these terms of use and agree to abide by them. If you do not agree to these terms of use, please do not use our site.</p>
                                </blockquote>
                                <h3>About Us</h3>
                                <p>www.connectiongoal.com is a site operated by Miriam Bueno and a business of Connection Goal (“we” or “us”). Our email address is hello@connectiongoal.com</p>
                                <h3>Purchases</h3>
                                <p>If you wish to purchase any product or service made available through the Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.<br/><br/>
                                You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.<br/><br/>
                                The service may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.<br/><br/>
                                We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.<br/><br/>
                                We reserve the right to refuse or cancel your order if fraud or an unauthorised or illegal transaction is suspected.<br/>
                                </p>
                                <h3>Availability, Errors and Inaccuracies</h3>
                                <p>
                                We are constantly updating product and service offerings on the Service. We may experience delays in updating information on the Service and in our advertising on other websites. The information found on the Service may contain errors or inaccuracies and may not be complete or current. Products or services may be misplaced, described inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or completeness of any information found on the Service.<br/><br/>
                                We therefore reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.<br/>
                                </p>
                                <h3>Contests, Sweepstakes and Promotions</h3>
                                <p>Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through the Service may be governed by rules that are separate from these Terms & Conditions. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms and Conditions, the Promotion rules will apply.</p>
                                <h3>Our Site</h3>
                                <p>We allow access to our site on a temporary basis and we reserve the right to withdraw, restrict or change our site at any time and without notice. We will not be liable if for any reason our site is unavailable at any time or if the content is changed or out of date.<br/><br/>
                                You must treat as confidential any user identification code, password or other security feature in relation to our site. If, in our opinion, you aren’t complying with these terms of use, we have the right to disable any such code, password or feature at any time.<br/><br/>
                                You must comply with the provisions of our Acceptable Use Policy when using our site.<br/><br/>
                                It is your responsibility that anyone who accesses our site through your internet connection is aware of these terms and complies with them.<br/>
                                </p>
                                <h3>Variations</h3>
                                <p>
                                We may revise these terms of use at any time by amending this page or by provisions or notices published elsewhere on our site.
                                </p>
                                <h3>Intellectual Property Rights</h3>
                                <p>
                                We are the owner or the licensee of all intellectual property rights in our site and the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.<br/><br/>
                                You must not use any part of the materials on our site for commercial purposes without a license from us or our licensors. You may not reproduce in any format (including on another website) any part of our site (including content, images, designs, look and feel) without our prior written consent.<br/><br/>
                                If, in our opinion, you are in breach of these provisions, your right to use our site will cease immediately and you must either return or destroy (as required by us) any copies of the materials you have made.<br/>
                                </p>
                                <h3>Reliance on Information and Links</h3>
                                <p>
                                The contents of our site (including links to other sites and resources provided by third parties) are for information only, and we shall not be liable for any use of, or reliance on, such materials. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.   
                                </p>
                                <h3>Information About You and Your Visits to our Site</h3>
                                <p>We process information about you in accordance with our Privacy Policy. By using our site, you consent to such processing and you warrant that all data provided by you is accurate.</p>
                                <h3>Linking to our Site</h3>
                                <p>You may link to our home page only if you have first obtained our written consent and provided that you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. We reserve the right to withdraw linking permission without notice.<br/><br/>
                                The website from which you are linking must comply in all respects with our Acceptable Use Policy and must be owned by you.<br/><br/>
                                You must not link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.<br/><br/>
                                Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.<br/><br/>
                                If you wish to make any use of material on our site other than that set out above, please address your request to hello@connectiongoal.com<br/>
                                </p>
                                <h3>Uploading Material to our Site</h3>
                                <p>
                                When you upload material to our site, or make contact with other users of our site, you must comply with our Acceptable Use Policy. If you upload material in breach of our Acceptable Use Policy and we suffer loss as a result, you will reimburse us for such loss.<br/><br/>
                                Any material you upload to our site will be considered non-confidential and non-proprietary and we have the right to use, copy, distribute and disclose it to third parties. If any third party claims that any material posted or uploaded by you to our site violates their intellectual property rights, or their right to privacy, we have the right to disclose your identity to them.<br/><br/>
                                We will not be responsible, or liable to any third party, for the content or accuracy of any materials posted by you or any other user of our site.<br/><br/>
                                We have the right to remove any material or posting you make on our site if, in our opinion, such material does not comply with the content standards set out in our Acceptable Use Policy.<br/>
                                </p>
                                <h3>Viruses, Hacking and Other Offenses</h3>
                                <p>You must not misuse our site by knowingly introducing any material which is malicious or technologically harmful. You must not attempt to gain unauthorized access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack.<br/><br/>
                                By failing to comply with this provision, you would commit a criminal offense and your right to use our site will cease immediately and we will report your actions to the relevant authorities.<br/>
                                </p>
                                <h3>Our Liability</h3>
                                <p>The material displayed on our site is provided without any guarantees, conditions or warranties as to its accuracy. To the extent permitted by law, we hereby expressly exclude:<br/>
                                    <ul>
                                        <li>All conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity.</li>
                                        <li>Any liability for any direct, indirect or consequential loss or damage incurred by any user in connection with our site or in connection with the use, inability to use, or results of the use of our site, any websites linked to it and any materials posted on it (whether by us or a third party), including, without limitation any liability for:</li>
                                        <li>loss of income or revenue;</li>
                                        <li>loss of business;</li>
                                        <li>loss of profits or contracts;</li>
                                        <li>loss of anticipated savings;</li>
                                        <li>loss of data;</li>
                                        <li>loss of goodwill;</li>
                                        <li>wasted management or office time; and for any other loss or damage of any kind, however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable.</li>
                                    </ul>
                                This does not affect any liability which cannot be excluded or limited under applicable law.
                                </p>
                                <h3>Jurisdiction and Applicable Law</h3>
                                <p>The English courts will have exclusive jurisdiction over any claim arising from, or related to, a visit to our site.<br/>
                                These terms of use and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.<br/>
                                </p>
                                <h3>Changes</h3>
                                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 15 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.<br/>
                                By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorised to use the Service.<br/>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/opportunities">
                                                Opportunities
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/terms-of-service">
                                                Terms of Service
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/acceptable-use-policy">
                                                Acceptable Use Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default TermsOfService;